import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "大規模プロジェクトの課題を解消する、たった1時間で行うふりかえりの工夫",
  "date": "2024-07-02T09:56:22.000Z",
  "slug": "entry/2024/07/02/185622",
  "tags": [],
  "hero": "./2024_07_02.png",
  "heroAlt": "大規模プロジェクトの課題を解消する、たった1時間で行うふりかえりの工夫"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Edit here! */
    }
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは。`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/karte"
      }}>{`CLINICS カルテ`}</a>{`の QA 担当をしております QA エンジニアの かみむら です。`}<br parentName="p"></br>{`
`}{`医療プラットフォーム本部 CLINICS 開発チームでは、2年以上に渡り自社レセコン`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`の開発を行っています。プロダクトは公開済みであるものの鋭意追加機能の開発を続けており、今後も継続して開発する予定になっています。`}</p>
    <p>{`QA エンジニアの大切な役割の1つとして、プロセス改善があります。ふりかえりはプロセス改善のアイデアを関係者全員で話し合うための肝となるアクティビティですので、規模の大小問わず取り入れたいものです。`}<br parentName="p"></br>{`
`}{`この記事ではレセコン開発におけるプロジェクト体制構築時の黎明期から現在の成熟期に至るまでに行った、四半期毎のふりかえり手法や効果について、かいつまんでご紹介します。`}</p>
    <h1>{`プロジェクトの状況とふりかえりの進め方`}</h1>
    <p>{`まずレセコン開発プロジェクトのチーム体制についてご説明します。メンバーは、初期から現在まで総勢20〜25名前後（内訳：ディレクター3〜5名、カスタマーサクセス2〜4名、エンジニア10数名、QA エンジニア1〜2名）で取り組んでいます。`}<br parentName="p"></br>{`
`}{`※開発詳細については、`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2023/07/31/120555"
      }}>{`Tech Studio MATSUE オフィスのご紹介`}</a>{`からご覧いただけます！`}</p>
    <p>{`元々医療プラットフォーム本部では四半期に一度、個々のプロジェクトの垣根なくプロダクトチーム全体でのふりかえりを KPT（ Keep / Problem / Try ）で行うという習慣があります。そこで、レセコン開発のプロジェクトも時期を合わせてふりかえりを行うようにすればメンバーの気持ちと時間の調整がしやすいのではないか？ と考え、プロジェクトマネージャーに相談しながら各四半期の終わり頃に開催することとしました。`}</p>
    <p>{`当プロジェクトには専任メンバーもいますが、多くのメンバーは他のプロジェクトとの兼務のためふりかえりへの時間の使い方と開催形式には気をつかいました。`}<br parentName="p"></br>{`
`}{`特に開発メンバーの半数近くが別拠点（松江オフィス）に勤務していることもあり、東京オフィスメンバーとのコミュニケーションのため完全オンライン形式にする必要がありました（同じ拠点同士は対面で集まり、拠点間のみオンライン接続するというやり方もありますが、経験上個々の発言が拾いにくい等負の側面があることをわかっていたため、全員オンラインでの参加としました）。`}</p>
    <p>{`具体的には次のように進めています。`}</p>
    <ol>
      <li parentName="ol">{`”今期の課題感” についてプロジェクトマネージャーとすり合わせ、その課題に合いそうなふりかえり手法を選定する（参加者の対象をどこまで広げるか、数名ずつのグループに分ける場合はどういう基準で分けるか等も相談）`}</li>
      <li parentName="ol">{`候補に挙げた手法とタイムテーブルを `}<a parentName="li" {...{
          "href": "https://www.atlassian.com/ja/software/confluence"
        }}>{`Confluence`}</a>{` に書き起こし、前日までに Slack で事前周知`}</li>
      <li parentName="ol">{`Google Meet でオンライン開催する`}</li>
      <li parentName="ol">{`ふりかえり結果の要約 / 抜粋 / アクションプランを Confluence に追記し、参加者に Slack で報告`}</li>
    </ol>
    <p>{`毎回以下の形式を守って執り行いました。`}</p>
    <ul>
      <li parentName="ul">{`完全オンライン形式で開催`}</li>
      <li parentName="ul">{`参加者の事前準備はほぼ無し`}</li>
      <li parentName="ul">{`ふりかえりイベント当日にかける時間は1時間だけ`}</li>
    </ul>
    <p>{`一般的には四半期という長期間を対象とするふりかえりの場合、半日くらいかけて開催してもおかしくないと思うのですが、対象者全員が空いている時間帯を確保するのもなかなか難しく「1時間以内でやり切り最大の効果をあげること」を自分に課していました。`}</p>
    <h1>{`具体例① 「象、死んだ魚、嘔吐」`}</h1>
    <p>{`開催時期：初期（2022年6月）`}<br parentName="p"></br>{`
`}{`参加メンバー：12名`}</p>
    <p>{`まだ「ふりかえりをこうしていこう！」とも決めていなかった頃の、最初に行ったふりかえりは「`}<a parentName="p" {...{
        "href": "https://no-kill-switch.ghost.io/elephants-dead-fish-vomit/"
      }}>{`象、死んだ魚、嘔吐`}</a>{`」という手法を採用しました。`}<br parentName="p"></br>{`
`}{`Airbnb 創業者が考案したコミュニケーション改善の手法で、以下の3要素から構成されています。`}</p>
    <ol>
      <li parentName="ol">{`「象」…存在しないかのように扱われるが、誰もが知っている事実。`}</li>
      <li parentName="ol">{`「死んだ魚」…過去に起こったこと（悪臭と腐敗）を乗り越えて、将来への期待を設定する。`}</li>
      <li parentName="ol">{`「嘔吐」…普段は言わないこと、言うのを控えていることなどをぶっちゃけて話す。`}</li>
    </ol>
    <p>{`最初期のためチーム全体としても遠慮し合うような雰囲気がまだうっすらとあり、松江と東京の物理的な距離感も相まって「もう全員でぶっちゃけて話そうよ！」という思いでこの手法を選びました（個人的にも気になっていた手法でした）。
少し前に行ったプロダクトチーム全体のふりかえりから、現在のチームは「良い面も悪い面もコミュニケーションのあり方がキーポイントになっていそう」という推測が出ていたことも背景にありました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "888px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ae1d5e1337ac363be4b6c0fcbc7e1945/b04e4/dev_20240702_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAABYlAAAWJQFJUiTwAAACTklEQVQoz1WSv08TYRyHb3J21P/Awcnd3cXoYMKGSEKiCSY4maAywIAScZCKIMJAMIGEKPgbIiGhQMuvQgulhV4Pru31uN717t7eez/eu/d9v6YkmvDsz/DJ8xHgnDAkYeAHvucg23OxZVqWbWOM64bBKI29HRobHYWLcM6FhFzeUlTCoYyw4pOE7e07PmUR5xHjjEURI97+fmY1Hqc04hwY5/99YWAzN7QrSiTqWN1NIZdQr6TXNB2f1bBmOMjx3AB8wknIAkLrVdWsVgGA0hBjJOQWugtLPWFERcN1SKgXHiB1WJVn1eIbW5sLfalc6EXaeCXXirQJxiFiDBh4ppXeiAvFydvqfDv+sVnsehceSqbS0dBicrZdEbtUfcqyllfmrij5zuLOzboS4wB+6J2ibzkzltR6BHT2pVYesb/Gj5+MNPbSVakda2P1fFvW1B8u7sUVCch65O2Cfxxq25R4Xoh39O5Z8fp4VhDUYm8+eY/hBmv4jPheqM4cbK9IySxCfalD0XSMwmMxc8tKf0g/F1zpNweo+1t/5DtreqsA4ELk8eaQJvWAt8z8fLWR/yyWO38tKW4QGKNI7Q+0HZQYjMxTAMjVprsmhY/r1wQOwM+rNcNxIJQdGLWKry3IpYGNYwMTAM44pZxjgBrlXgSaNb5wdCNVfiGcu/xf9wgAiub0+7VLGaN/WW6r2lONk7uO+hQAKsrUSWUCOank96vV3KNm54unoQBwZEwPJy5njJeLJy0K/tSQWrDWBwB25bVdegb0VD64r5cGOcBfbyQ1BW/3iAwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "elephants-dead-fish-vomit_image",
            "title": "elephants-dead-fish-vomit_image",
            "src": "/static/ae1d5e1337ac363be4b6c0fcbc7e1945/b04e4/dev_20240702_01.png",
            "srcSet": ["/static/ae1d5e1337ac363be4b6c0fcbc7e1945/5a46d/dev_20240702_01.png 300w", "/static/ae1d5e1337ac363be4b6c0fcbc7e1945/0a47e/dev_20240702_01.png 600w", "/static/ae1d5e1337ac363be4b6c0fcbc7e1945/b04e4/dev_20240702_01.png 888w"],
            "sizes": "(max-width: 888px) 100vw, 888px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`「象」「死んだ魚」「嘔吐」それぞれでボードを使用`}</em></p>
    <p>{`「四半期毎の開催」と述べましたが、実はこの最初のチーム全員のふりかえりまではプロジェクト開始から半年経過しています。そのためレセコン開発プロジェクト全体としては初回のふりかえりということもあり、「そもそも何のためにふりかえりを行うのか」「このプロジェクトの目的（共通目標）は何か」という部分は特に丁寧に対話する時間をとりました。`}<br parentName="p"></br>{`
`}{`ツールは Jamboard を使用し、心理的安全性を高めるためクローズドな状態にして「本当に何でも言える」環境をつくることに腐心しました。`}<br parentName="p"></br>{`
`}{`結果、1時間のうち集中して話ができたのは30分程度ですが、かなりいろんなぶっちゃけ話ができたと思います。もちろんこのふりかえりだけが要因ではないですが、その後のプロジェクトメンバー内のコミュニケーションは徐々に滑らかになっていってるな、チームビルディングという意味でも効果があったかなと感じました。`}</p>
    <h1>{`具体例② 「ポジティブふりかえりマッピング」`}</h1>
    <p>{`開催時期：初回リリース後（2023年3月）`}<br parentName="p"></br>{`
`}{`参加メンバー：25名`}</p>
    <p>{`初回リリース後の運用期間を経て次の大型リリースの準備を進めていく中で、関わる人数やロールも一段と増えた時期でした。これまではふりかえりに参加していなかったカスタマーサクセス等のメンバーも招待し、次の期へのキックオフを兼ねて明るい雰囲気で未来へつながっていくお話をしたく、ポジティブふりかえりマッピングを使うことにしました。`}<br parentName="p"></br>{`
`}{`この手法は、はじめに「どんな道をたどったにせよ、当時の知識・技術・能力・利用可能なリソース・状況の中で、みんなができる限り最高の仕事をしたはずです。それを心から信じます。」という声明を発表し、「あなたは⚪︎⚪︎プロジェクトにいましたね。どういう点が素晴らしかったですか？」と質問を続けるという作法で進みます。メンバーが次々と素晴らしい点を挙げていくので、みんなが嬉しく和やかな雰囲気になります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1147px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b5826dfc1b2ac0e710550c25bfe3aa96/dc61a/dev_20240702_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAABYlAAAWJQFJUiTwAAABMklEQVQoz5WS227cMAxE9///MUWT7q5lS5bEIamrLQVpXoIA6W4HBN8OhhzyYvdo9/B6NbfF5VL7ccyndfGBQoTbA1gILCwgFlGAWRKzUESrRUWYoKpEANBrnWNccqneU4FvCGeWglAoVEHytqkUxKrScqpMhdGS9pJb1qZytHqZc47zVOB+vf16+b1t1q6WgW0x5r4wobf249hzzta6J3WBfZTSjlJ7P8Zxzn6OBzvPOUvt9w170M3rurOLabEwDlvQx3Ct7bZsBPaBNrtryqv1LsRI/BhuvROYIBEcP6JOkRis4OecF+PAopoJwqIhQjSxpgfwGINYNudb62N8JPSlf9Y/nXdPr3+MWZ38tfqEn/qwOWfOdVnd3djVerDWnw/7HT7Oc/f0djXL6kLA/B+9A+XT9t7bzVeQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ポジティブふりかえりマッピングの進め方",
            "title": "ポジティブふりかえりマッピングの進め方",
            "src": "/static/b5826dfc1b2ac0e710550c25bfe3aa96/dc61a/dev_20240702_02.png",
            "srcSet": ["/static/b5826dfc1b2ac0e710550c25bfe3aa96/5a46d/dev_20240702_02.png 300w", "/static/b5826dfc1b2ac0e710550c25bfe3aa96/0a47e/dev_20240702_02.png 600w", "/static/b5826dfc1b2ac0e710550c25bfe3aa96/dc61a/dev_20240702_02.png 1147w"],
            "sizes": "(max-width: 1147px) 100vw, 1147px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`当時のConfluence`}</em></p>
    <p>{`参加メンバーも大人数になってきたので、5名ずつのグループをあらかじめつくっておき、グループ毎の Jamboard へ意見を記載してもらいました。持ち時間は10分です。時間短縮のためブレイクアウトルームはつくらず、25名全員がメインルームに参加したままの状態で各自ふせんをつくっていきます。`}<br parentName="p"></br>{`
`}{`キックオフとして有用な場になるよう、この回のグループ編成は以下の点に配慮しました。`}</p>
    <ul>
      <li parentName="ul">{`同じグループ内でなるべく職種が偏らないように`}</li>
      <li parentName="ul">{`年齢（経験年数）も偏らないように`}</li>
      <li parentName="ul">{`とはいえまったく業務上の接点もないということがないように`}</li>
    </ul>
    <p>{`次に各グループ毎に挙がった内容が近いふせんを寄せたり、ペンで囲んだりといったグルーピングを5分で行います。`}<br parentName="p"></br>{`
`}{`最後に各グループの結果を全員で見ていき要約する時間を15分とります。`}<br parentName="p"></br>{`
`}{`メンバーからは「プロジェクトの推進における各自の機動力、組織力が素晴らしい」「効率化のための仕組みが効いて次期稼働可能な状態までもっていけた」等の意見が挙がりました。`}<br parentName="p"></br>{`
`}{`素晴らしい点をお互いに見つけて言語化することによって労いや協力の気持ちが思い出され、ふりかえりとしてもキックオフとしても良いイベントになったかなと思います。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9ef96c5b7c4280ffe686e059edab694c/e0202/dev_20240702_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAABYlAAAWJQFJUiTwAAACgUlEQVQozy2RT0gUUQDGh6BLgdAlxIqIoENZp7x4CA2jgxAREugpqkMoQUpRWBDdIvJSUJkgrARZa0lhZSpm1i666pq6jrvrus7uzM44O7Mz8+bvezNv3ouNbh98/Pjg9zGyA6HteYqOdBsIO0aB800AAXA1HZtAE3ipwAP+k5l/YWYi/s67QB0KtERoeSTEjC6+FzY6kDlkSvf5rWfJFbYkiSVJKokSRMiDUDOspZmWieheMXsxnWj68bFG5h5TSgkJGD7bl5qtA8Kt1ZkD5dz1AFNKMaWE0v8hJLSUuZlfanalTu5PS37haKA/gajaMjvcy82FJgS+UJoLUA75oQepAVzbCQNMCSGu6wDxkZy97KrPbbFTZk9IG63QUymlTGb53vTIbqvyGRMKjAqX7pNyD3w7bpZHysUBCwiB76/Fz48O7pLzvbmVnuFXe+a/1yNPqMKakqxIo7aZh4hW1O35yYaxNzWa8DT+7WR8bL8iTjsuLrLdqVizXe63lAE52w6EbgSNKowxUTUXmKAkpHgupua7imttyIxsLbfxqQumvuo4VQ2Bu6hxV9TtTlfutaS7gdqFrUGGEEwIDWBuefbs+Eijwj3kVtsT061IueqJbdLGJctIUkp9Ozb14eBE9NB2snFxqn787T4xe4OhJKyKg+mpkdrh/n3FVMdktG5h4nBpveXr8JHJaK2t/6wuO79l9riSPmUXz4nsmc35Y175NkP+vUKwpBXu2GKPrUSEXKTCvy6x17YSp43NBoLmqjBUPPDLt2LITGK/QClPQ4UpOt6KbiUqVtomnEezFk7bJKmRhCysqZm4zK7rlazpZoCbNkLWCOcUmNR8zqFFB/8FvSAbCj2CbmgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ポジティブふりかえりマッピング",
            "title": "ポジティブふりかえりマッピング",
            "src": "/static/9ef96c5b7c4280ffe686e059edab694c/c1b63/dev_20240702_03.png",
            "srcSet": ["/static/9ef96c5b7c4280ffe686e059edab694c/5a46d/dev_20240702_03.png 300w", "/static/9ef96c5b7c4280ffe686e059edab694c/0a47e/dev_20240702_03.png 600w", "/static/9ef96c5b7c4280ffe686e059edab694c/c1b63/dev_20240702_03.png 1200w", "/static/9ef96c5b7c4280ffe686e059edab694c/e0202/dev_20240702_03.png 1395w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`とあるグループの最終形態`}</em></p>
    <h1>{`具体例③ 「温度計」`}</h1>
    <p>{`開催時期：成長期（2023年12月）`}<br parentName="p"></br>{`
`}{`参加メンバー：19名`}</p>
    <p>{`自社レセコンが稼働する医療機関数の更なる増加と診療報酬改定`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{`に向けて、持続的なプロダクト開発とオペレーション改善のための話し合いを行いたい時期でした。`}<br parentName="p"></br>{`
`}{`このふりかえりでは、以下の3点を重要課題と位置づけました。`}</p>
    <ul>
      <li parentName="ul">{`良いところを伸ばす`}</li>
      <li parentName="ul">{`問題を解消する`}</li>
      <li parentName="ul">{`関係の質を高める`}</li>
    </ul>
    <p>{`この3点の目的にマッチする「温度計」という手法は、チームメンバーが「チームに起きていること」「チームに望むこと」を報告し合います。現在位置から今後に視点を向けることを意識するため、以下の3ステップで進めました。`}</p>
    <ol>
      <li parentName="ol">{`感謝・興奮`}</li>
      <li parentName="ol">{`気づき・興味`}</li>
      <li parentName="ol">{`提案・希望・願望`}</li>
    </ol>
    <p>{`「温度計」という名の通り、中央に温度計のイラストを配置します。本来は縦長の用紙下部に低温（よくないこと）、上部にいくほど高温（興奮や願望）のふせんを貼る、というルールになっていますが、オンライン画面上ではスクロールが必要な状況を避けたかったため、各ステップのふせんの色を変えることにより（青→黄色→ピンク）温度感を表現することにしました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1089px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8c1bdefc9b515518f6e2bf85491f52de/96e86/dev_20240702_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAACYklEQVQoz32OWU8TARSF5x/5O3zx3V/go4++GE2MaBAUxdgWNBCkBJR9p7QQKSUsXWDa0ilLZ0oLpe10m5a2dJn5DBVRJPEmN7m5J+c7R9jJ5nGPrbIwtcJrMcyrtQ16tlzMHkexBsN0u/cYkkQatRw1bZOM/Ij8mZWoy4riGkKNL1KviDTrOZJHjxFMIRmzy8cL+wbdvgBdLieTK4Ns+rdo8xxgcq8SCT0lHbegqbOUCw6O3fc5drxDWrKQVL5Tzk+jG5cYehLBk1bJNRoE0xm6diVGftg56exhRxR5L8kMbXvRrMPo1RQJuYNi8iNJxYK0aCZk6yUeaicmPSAydY9KeA5hKXbKvBxnIhJjNp7gs8OGb8SC1eNjR3HT4wswsK5Qa0C1FKZasFFQvUjzZkKL3eSSXrTEEyrxQYyyhmASD3jpDvJ8Z4+JQz8f1lYZtfXR4d1lwLOOyePgk/iQul7iaqqVAPFIBwcznexPt6Fl1db/QnOST1kRpEwOv5pDzGqcXZSJxo44kLwEVY2QKhORHRxG+6jXShhA/SJN8XiPs/A2pyEXlbLW+lfLCtViAIF/x4Cy3cPltp9c/zLxma8c2c2o0VBLrqc08gu+2xZDv7kF3TD4vVdJeqPJef8CJ2+GOe+dQ3GNEVh4S0rx34boegtkGMYN9GqFPynGjVjyhCluBGlW6sieGcSJdtIn+2Ttu2Qm12kWLu54bhreAuq/qqvjTjIT661b2RzF/+0Z6ZMANTlN6ss8ZTHyV8v/Aa/Fou+Q7NAKzUyJs7CTw2Uz+WyMZqJAYdxFPZG9Bt5t+BONHhLOSj+knAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "温度計",
            "title": "温度計",
            "src": "/static/8c1bdefc9b515518f6e2bf85491f52de/96e86/dev_20240702_04.png",
            "srcSet": ["/static/8c1bdefc9b515518f6e2bf85491f52de/5a46d/dev_20240702_04.png 300w", "/static/8c1bdefc9b515518f6e2bf85491f52de/0a47e/dev_20240702_04.png 600w", "/static/8c1bdefc9b515518f6e2bf85491f52de/96e86/dev_20240702_04.png 1089w"],
            "sizes": "(max-width: 1089px) 100vw, 1089px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`「感謝・興奮」は全員で1つのボードを使用し、ありがとうの気持ちが溢れました`}</em></p>
    <p>{`「大変だけど楽しい期だった」「チーム感があった」「インフラチームのおかげで開発・不具合対応に集中できた」等の意見があがり、初期から比べると本当に良いチームになっているなと感じました。`}<br parentName="p"></br>{`
`}{`またこの頃から「⚪︎⚪︎に関する勉強会 / 品評会をしてみたい」という意見も徐々に増えてきており、一部実現したものもありますが未だにやれていないジャンルもあり、熱が冷めないうちに何らかの形で達成することが今後の課題です。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`ここまで、実際に行ったふりかえり事例を3つご紹介しました。`}<br parentName="p"></br>{`
`}{`最後に、どのふりかえりでも共通して行っていたことを踏まえてまとめます。`}</p>
    <ul>
      <li parentName="ul">{`ふりかえりをする意義を再認識し、場をつくる`}</li>
      <li parentName="ul">{`前回のふりかえりをふりかえる`}</li>
      <li parentName="ul">{`他のメンバーが挙げた意見に賛成や近い意見がある場合は、ドット投票を行う`}</li>
    </ul>
    <p>{`以下に詳しくご説明します。`}</p>
    <h3>{`ふりかえりをする意義を再認識し、場をつくる`}</h3>
    <p>{`メンバー全員が「ふりかえり」に慣れているわけではなく、経験が少ないメンバーもいることから、「なぜふりかえりをするのか？」という意義については毎回冒頭で触れて、気持ちをつくっていくということをしていました。毎回別の言葉で、「たいへんな時ほど立ち止まって周りをみることが、これからも一緒に走っていく仲間として大切」のようなお話をしました。`}<br parentName="p"></br>{`
`}{`会の前半は説明のため私が1人で話している時間も長めで、オンラインでは参加者の反応がわかりづらくちょっとした無音の時間が続いてしまいがちです。特別にアイスブレイクの時間をとっていないこともあり自然に場を盛り上げていくために、参加メンバーには「環境的にミュート解除しても問題ない人はなるべく声出してください〜」「スタンプくださーい」等と促してわいわいしてもらいました。`}</p>
    <h3>{`前回のふりかえりをふりかえる`}</h3>
    <p>{`四半期毎にふりかえりをする、というリズムもできたので、毎回冒頭で「前回はこういうふりかえりをしましたね」という「ふりかえりのふりかえり」をするようにしています。`}<br parentName="p"></br>{`
`}{`あまり時間もとれないのでさらっと触れる程度ですが、今から行うふりかえりの気持ちをつくる上でも重要なパートかなと思っています。`}</p>
    <h3>{`誰かが挙げた意見に賛成や近い意見がある場合は、ドット投票を行う`}</h3>
    <p>{`「ドット投票」もふりかえりの手法の1つですが、あらかじめマークとなるアイコンを準備しておき、それを各自コピーして共感した他の人のふせんの近くに貼ってもらいました。`}<br parentName="p"></br>{`
`}{`これによりみんなの関心がある課題が明確になって時間も短縮でき、取り上げる議題に集中できます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "865px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5fe1304dd0d5ce5b7418985a696b16ff/79e48/dev_20240702_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAABYlAAAWJQFJUiTwAAABrUlEQVQY022R7UtTYRjG93f1D/RJiGVEJFHfgojAoDTocyEkJSgSY2nYMbZZ28kxm01tigqN2E7l3IubnM2dTVvntOfsPM9zXp5LzmQfgn5cH+/fzXVzB0zGDEqJ52GIAAS1QTn+RfQsYZieTjxiCX8KgZlvhbvZfLx8QP6c9TlDs8u/V1l8H7V227aLOqn1WdWkTYu7VU0cnqCsQSOurXOjEqhTu2Do/SOpX0pxoPlmLXH5nvLgFQiP1JvXlj7cia6OLkae5X4BsHKl2svIwXSsMj/HG58CfqHfW5v5pFQ8rtLW2cLb7OhT5f4MeiymtkZmwzdCS2OR1ec/VQDd2Nf1kfEvV5/UJl8wdduXmZaeTK9fep1c2IsiL/EdRY/PwcXHRicYXr4Zlq6/kyUlC5BOfDcTfLRyZbz0eMptDGS7m1vcXp5IyWuFJIwNATheG8DKcWtMztx6n7gtb3zORwGjl1GOHs5WJkLtqZBVln1ZOKajpZyTqKvto6iirInSKbrk1HYODVIxad2ippqGQ+H6i+FB2MQ1fgxuFsM/eQDh6Fn4a4E5+B9ikAvOAZc4mJrODCyRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ドット投票の例",
            "title": "ドット投票の例",
            "src": "/static/5fe1304dd0d5ce5b7418985a696b16ff/79e48/dev_20240702_05.png",
            "srcSet": ["/static/5fe1304dd0d5ce5b7418985a696b16ff/5a46d/dev_20240702_05.png 300w", "/static/5fe1304dd0d5ce5b7418985a696b16ff/0a47e/dev_20240702_05.png 600w", "/static/5fe1304dd0d5ce5b7418985a696b16ff/79e48/dev_20240702_05.png 865w"],
            "sizes": "(max-width: 865px) 100vw, 865px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`ドット投票の例`}</em></p>
    <h2>{`ふりかえり手法の選定について`}</h2>
    <p>{`さて、私がなぜ毎回課題感に合わせて最適な（と思われる）ふりかえり手法を使うことができたのか、それはふりかえり＆ Miro エバンジェリストである`}<a parentName="p" {...{
        "href": "https://x.com/viva_tweet_x"
      }}>{`びばさん`}</a>{`の『`}<a parentName="p" {...{
        "href": "https://booth.pm/ja/items/1711909"
      }}>{`ふりかえりチートシート`}</a>{`』にかなりお世話になっているためです。`}<br parentName="p"></br>{`
`}{`このシートには各ふりかえり手法がどの目的（解消したい課題）に適しているか詳細にマッピングされているので、「今回のふりかえりで解決 / 達成したいこと」を2〜3つ選定し、その中から合いそうな手法をピックアップして詳しく調べてから1時間でやり切れる形式に落とし込んでいきました。`}<br parentName="p"></br>{`
`}{`また、課題によっては1つの手法に収めず、複数の手法を組み合わせて使うことも多いです。前述の「ドット投票」もそうですが、軽量な手法である「感謝」（自分が感謝すべき人たちのことを考え、精一杯の誠意を込めて「ありがとう」と言う）は他の手法と組み合わせてアイスブレイク的に使ったりもしました。`}</p>
    <p>{`このように、毎回異なるふりかえり手法を取り入れることのできる柔軟な組織で一緒に働いてみたい！ と思った方はお気軽に`}<a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`カジュアル面談`}</a>{`をお申し込みください。`}<br parentName="p"></br>{`
`}{`良いふりかえり事例を持っている、あるいはふりかえりの仕方に悩んでいる等とにかく語りたい方も一度お話ししてみましょう！`}<br parentName="p"></br>{`
`}{`最後までお読みいただき、ありがとうございました。`}</p>
    <h3>{`Reference&Thanks（ふりかえりの参考図書）`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://booth.pm/ja/items/1076615"
        }}>{`ふりかえり読本 場作り編～ふりかえるその前に～`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amazon.co.jp/Fearless-Change-%E3%82%A2%E3%82%B8%E3%83%A3%E3%82%A4%E3%83%AB%E3%81%AB%E5%8A%B9%E3%81%8F-%E3%82%A2%E3%82%A4%E3%83%87%E3%82%A2%E3%82%92%E7%B5%84%E7%B9%94%E3%81%AB%E5%BA%83%E3%82%81%E3%82%8B%E3%81%9F%E3%82%81%E3%81%AE48%E3%81%AE%E3%83%91%E3%82%BF%E3%83%BC%E3%83%B3-Manns/dp/462108786X"
        }}>{`Fearless Change アジャイルに効く アイデアを組織に広めるための48のパターン`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://shop.ohmsha.co.jp/shopdetail/000000001770/"
        }}>{`アジャイルレトロスペクティブズ　強いチームを育てる「ふりかえり」の手引き`}</a></li>
    </ul>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`レセプトコンピュータ。レセプト（診療報酬明細書）を作成するためのコンピュータ。`}<br parentName="li"></br>
          <a parentName="li" {...{
            "href": "https://clinics-cloud.com/column/64"
          }}>{`https://clinics-cloud.com/column/64`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`通常2年毎に行われる、医療行為に対する点数の見直し。`}<br parentName="li"></br>
          <a parentName="li" {...{
            "href": "https://clinics-cloud.com/column/406"
          }}>{`https://clinics-cloud.com/column/406`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      